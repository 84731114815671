import React, { useEffect, useState } from 'react'
import API from '../../../apis'
import { Link } from 'react-router-dom'
import { IoLogoWhatsapp } from "react-icons/io";
import { CommonLoader, CommonFormTextArea, CommonFormTextInput, CommonFormButton, CommonError } from '../..'

const Support = () => {
    const [RM, setRM] = useState(null)
    const [subject, setSubject] = useState(null)
    const [desc, setDesc] = useState(null)
    const [error, setError] = useState(null)
    const [submitted, setSubmitted] = useState(false)

    useEffect(() => {
        const rmDetails = async function () {
            const response = await API.support.rmDetails()
            if (response.status === 200 && response.data?.data) {
                setRM(response.data.data)
            }
        }
        rmDetails()
    }, [])

    async function submit() {
        setError(null)
        const response = await API.support.createTicket({ subject, desc })
        if (response.status === 200) {
            setSubmitted(true)
            setDesc(null)
            setSubject(null)
        } else {
            setError(response.error)
        }
    }

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">Support</h6>
            <hr className="mt-3 text-gray" />
            {RM === null && <p className="text-center mt-4"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Loading...</p>}
            {RM && <div className="flex flex-col space-y-2 mt-4">
                <p className="font-medium">My Relationship Manager</p>
                <p className="ml-4">Name: {RM.name}</p>
                <p className="ml-4"><Link target="_blank" to={RM.mobile ? "https://wa.me/91" + RM.mobile : "#"}>Mobile: <IoLogoWhatsapp className="inline-block mr-0.5 -mt-1 text-[#25D366]" /><span className="border-b">{RM.mobile}</span></Link></p>
                <p className="ml-4">Email: {RM.email}</p>
            </div>}
            <div className="flex flex-col space-y-4 mt-8 w-full lg:w-1/2 xl:w-1/3">
                <p className="font-medium">Facing issues? Contact Us</p>
                {error && <CommonError error={error} className="" />}
                {!submitted && <CommonFormTextInput id="sk-support-subject" label="Subject" className="w-full" onChange={setSubject} />}
                {!submitted && <CommonFormTextArea id="sk-support-desc" label="Description" className="w-full" onChange={setDesc} maxLength="255" numRows="3" />}
                {!submitted && <CommonFormButton text="Submit" isOutline={true} onClick={submit} />}
                {submitted && <p>You're request has been submitted.</p>}
            </div>
        </div>
    )
}

export default Support