import React, { useState, useEffect, isValidElement } from 'react'
import './text-input.css'
import PropTypes from "prop-types"
import { isMobile } from 'react-device-detect'
import { default as SelectMobile } from './select-mobile'

const Select = (props) => {
    let [isShown, setIsShown] = useState(false)
    let [selectedValue, setSelectedValue] = useState([null, null])
    let [showTop, setShowTop] = useState(false)

    function selectUsingArrows(event) {
        if (event.keyCode === 27) {
            hideDropdown()
        }
    }

    function showDropdown(e) {
        if (e) e.preventDefault()
        if (props.disabled) return
        if (isShown === false) {
            setIsShown(true)
            document.addEventListener("keydown", selectUsingArrows)
        }
    }

    function hideDropdown(e) {
        if (e) e.preventDefault()
        if (isShown === true) {
            setIsShown(false)
            document.removeEventListener("keydown", selectUsingArrows)
        }
    }

    useEffect(() => {
        if (isShown === true) {
            const ele = document.getElementById(props.id + "_selection")
            ele.focus()
            const rect = ele.getBoundingClientRect()
            const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
            if (rect.top + rect.height > vh) {
                setShowTop(true)
            }
        } else {
            setShowTop(false)
        }
    }, [isShown, props.id])

    function selectValue(val) {
        setIsShown(false)
        setSelectedValue(val)
        typeof props.onChange === "function" && props.onChange(val[0])
    }

    useEffect(() => {
        if (props.defaultValue) {
            setIsShown(false)
            setSelectedValue(props.defaultValue)
        }
    }, [props.defaultValue])

    return (
        <>
            {isMobile
                ? <SelectMobile id={props.id} defaultValue={props.defaultValue} onChange={props.onChange} className={props.className} disabled={props.disabled} placeholder={props.placeholder} label={props.label} children={props.children} />
                : <div className={"inline-block relative border rounded-xl border-sk-blue py-3 px-6 focus:outline-none " + (props.className ? props.className : "") + " " + (props.disabled ? "bg-gray-100 bg-opacity-50 w-full" : "bg-white")} tabIndex="0" onClick={showDropdown} onFocus={showDropdown} onBlur={hideDropdown}>
                    <label htmlFor={props.id} className="text-sm block text-sk-blue font-semibold">{props.label}</label>
                    <p id={props.id} className={"font-medium w-full flex items-center justify-between select-none " + (!(selectedValue[0]) && "text-gray-500")}>
                        <span className=''>{selectedValue[1] ? selectedValue[1] : (props.placeholder ? props.placeholder : "Select")}</span>
                        <img className={"w-3 h-3 inline float-right invert opacity-30 transition-transform " + (isShown ? "rotate-180" : "")} src="/assets/icons/arrow-down.svg" alt="arrow" />
                    </p>
                    <div id={props.id + "_selection"} className={"flex flex-col absolute z-40 drop-shadow-lg left-0 min-w-full rounded-xl border bg-white border-gray-100 focus:outline-none max-h-52 whitespace-break-spaces " + (React.Children.count(props.children) > 5 ? "overflow-y-scroll" : "") + " " + (isShown ? "" : "hidden") + " " + (showTop ? "bottom-10" : "top-18 mt-1")} onBlur={hideDropdown}>
                        {React.Children.map(props.children, (x, i) =>
                            <p key={i} className="px-4 py-2 font-medium w-full rounded-xl bg-white hover:bg-gray-100 flex items-center justify-between " onClick={() => selectValue([x.props.value, x.props.display || x])}>
                                <span>{isValidElement(x) ? x.props.children : x}</span>
                                {x.props.value === selectedValue[0] && <img src="/assets/icons/green-tick.svg" alt="step" className="w-4 h-4 inline float-right" />}
                            </p>
                        )}
                    </div>
                </div>
            }
        </>
    )
}

Select.propTypes = {
    defaultValue: PropTypes.array,
    id: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.any,
    disabled: PropTypes.bool,
    
}

export default Select