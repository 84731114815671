import React, { useState } from "react"
import HomeSearch from "./pages/home-search"
import SearchResults from "./pages/search-results"
import DoSearch from "./pages/do-search"
import Constants from "../../../config/constants"
import { CommonError } from "../.."

const Flights = () => {
    const [view, updateView] = useState(0)
    const [data, setData] = useState(null)
    const [searchResponse, setSearchResponse] = useState(null)
    const [type, setType] = useState(null)

    const setView = (_view, _data, _searchResponse, _type) => {
        if (_data) setData(_data)
        if (_searchResponse) setSearchResponse(_searchResponse)
        else setSearchResponse(null)
        if (_type) setType(_type)
        updateView(_view)
    }

    const logos = Constants.FLIGHTS.PARTNER_LOGOS

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">Search Flights</h6>
            <hr className="mt-3 text-gray" />
            {view === 0 && <>
                <HomeSearch className="mt-20" setView={setView} />
                <div className="flex flex-wrap justify-center mt-4 gap-x-4 gap-y-2 max-w-screen-md self-center">
                    {Object.entries(logos).map(([key, val]) => <div key={key} className="h-16 w-16 min-h-16 max-h-16 lg:h-32 lg:w-32 lg:min-h-32 lg:max-h-32 p-0 lg:p-4 flex items-center justify-center hover:bg-gray-100 rounded-sm *:object-scale-down">
                        <img src={val} alt={key + "-logo"} className="w-full h-full inline-block" />
                    </div>)}
                </div>
            </>}
            {view === 1 && <DoSearch data={data} setView={setView} />}
            {view === 2 && <SearchResults setView={setView} searchData={data} searchResponse={searchResponse} defaultType={type} />}
            {view === 9 && <p className="text-center mt-4"><CommonError error={searchResponse} /></p>}
        </div>
    )
}

export default Flights