const Constants = {
    SessionVariables: {
        UserInitials: "user.initials",
        FirstName: "user.firstName",
        UserType: "user.type",
        WalletBalance: "wallet.balance",
        Permissions: "user.permissions"
    },
    APPLICATION_STATUS: {
        "ALL": "ALL",
        "INCOMPLETE": "INCOMPLETE",
        "IN-PROGRESS": "IN-PROGRESS",
        // "REJECTED": "REJECTED",
        "APPROVED": "APPROVED",
        "LINK_SHARED": "LINK_SHARED",
        "DISBURSE_PENDING": "DISBURSE_PENDING",
        "DISBURSED": "DISBURSED",
        "EXPIRED": "EXPIRED"
    },
    INSURANCE: {
        TRIP_TYPE: {
            1: "Single Trip",
            2: "Multi Trip"
        },
        DESTINATION_TYPE: {
            1: "Domestic",
            2: "Worldwide with US & Canada",
            3: "Worldwide without US & Canada"
        }
    },
    CUSTOMER_VIEWS: {
        "PLEASE_WAIT": 0,
        "MOBILE_NUMER": 1,
        "SEND_OTP": 10,
        "ENTER_OTP": 20,
        "DETAILS": 30,
        "MORE_DETAILS": 35,
        "REJECTED": 40,
        "OFFERS": 50,
        "LENDER_REDIRECTION": 60,
        "PROCESSING_FEE": 70,
        "COMPLETE": 80,
        "EXPIRED": 95,
        "UNDER_PROGRESS": 96,
        "SESSION_EXPIRED": 97,
        "API_ERROR": 98,
        "INTERNAL_ERROR": 99,
    },
    SANKASH_LOGO_URL: "https://sankash.in/assets/images/sankash-logo.svg",
    BACKOFFICE: {
        APPLICATION_STATUS: {
            "ALL": "ALL",
            "MORE_DETAILS": "MORE_DETAILS",
            "DISQUALIFIED": "DISQUALIFIED",
            "APPROVED": "APPROVED",
            "REJECTED": "REJECTED",
            "LINK_SHARED": "LINK_SHARED",
            "KYC_PENDING": "KYC_PENDING",
            "KYC_REJECTED": "KYC_REJECTED",
            "PF_PENDING": "PF_PENDING",
            "DISBURSE_PENDING": "DISBURSE_PENDING",
            "DISBURSED": "DISBURSED",
            "EXPIRED": "EXPIRED"
        },
        INSURANCE_STATUS: {
            "ALL": "ALL",
            "NEW": "NEW",
            "SCHEDULED": "SCHEDULED",
            "IN_PROGRESS": "IN_PROGRESS",
            "GENERATED": "GENERATED",
            "ERROR": "ERROR",
            "FAILED": "FAILED",
            "CANCELLATION_REQUESTED": "CANCELLATION_REQUESTED",
            "CANCELLATION_COMPLETED": "CANCELLATION_COMPLETED",
            "CANCELLATION_REJECTED": "CANCELLATION_REJECTED"
        }
    },
    BACKOFFICE_USER_PERMISSIONS: {
        "ALL": "ALL",
        "ADMIN": "ADMIN",
        "DASHBOARDS": "DASHBOARDS",
        "MANAGE_PARTNERS": "MANAGE_PARTNERS",
        "TNPL": "TNPL",
        "TI": "TI",
        "WALLET": "WALLET",
        "PAYMENT_COLLECTION": "PAYMENT_COLLECTION",
    },
    LENDERS: {
        "AB": "ABFL",
        "AP": "APOLLO",
        "AX": "Axis",
        "CS": "CASHe",
        "FB": "Fibe",
        "FM": "FlexMoney",
        "FZ": "Finzy",
        "HD": "HDBFS",
        "IC": "ICICI",
        "LT": "LoanTap",
        "VT": "VoltMoney",
        "ZS": "ZestMoney"
    },
    PRODUCT_TYPE: {
        "SUBVENTION": "Subvention",
        "NON_SUBVENTION": "Non-Subvention"
    },
    FLIGHTS: {
        PARTNER_LOGOS: {
            "TBO": "https://storage.googleapis.com/sankash-public/cdn/flight-partners/tbo-logo.png",
            "REZ": "https://storage.googleapis.com/sankash-public/cdn/flight-partners/rezlive-logo.png",
            "GRN": "https://storage.googleapis.com/sankash-public/cdn/flight-partners/grn-logo.png",
            "TPJ": "https://storage.googleapis.com/sankash-public/cdn/flight-partners/tripjack-logo.png",
            "RIY": "https://storage.googleapis.com/sankash-public/cdn/flight-partners/riya-logo.png",
            "MMT": "https://storage.googleapis.com/sankash-public/cdn/flight-partners/mkt-logo.png",
            "AKB": "https://storage.googleapis.com/sankash-public/cdn/flight-partners/akbar-logo.png",
            "EMT": "https://storage.googleapis.com/sankash-public/cdn/flight-partners/emt-logo.png"
        },
        DEFAULT_SORT_ORDER: ["TPJ", "TBO", "AKB"],
        REFUNDABLE: { 1: "REFUNDABLE", 2: "PARTIAL-REFUNDABLE", 0: "NON-REFUNDABLE" },
        PARTNER_NAMES: {
            "TBO": "TBO",
            "REZ": "RezLive",
            "GRN": "GRNConnect",
            "TPJ": "TripJack",
            "RIY": "Riya",
            "MMT": "MakeMyTrip",
            "AKB": "Akbar",
            "EMT": "EaseMyTrip"
        }
    }
}

export default Constants