import React, { useEffect, useState, useRef } from 'react'
import { default as MenuItem } from './menu-item'
import { default as SubMenuItem } from './menu-subitem'
import { default as MenuItemStatic } from './menu-item-static'
import { Link } from 'react-router-dom'
import NavConfig from '../../../config/nav-config'
import Constants from '../../../config/constants'
import { IoCloseSharp } from "react-icons/io5";
import { CommonLoader } from '../..'
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { isMobile } from 'react-device-detect'

const Nav = (props) => {
    let [navData, setNavData] = useState({ menuActiveFlags: {}, subMenuActiveFlags: {} })
    const baseItems = useRef(NavConfig[props.base])
    const [items, setItems] = useState([])

    useEffect(() => {
        let localItems = []
        const sessionPermissions = window.sessionStorage.getItem(Constants.SessionVariables.Permissions) || ""
        const userPermissions = sessionPermissions.split(",")

        // in case permission has not been loaded up
        if (sessionPermissions === "") return setItems(localItems)

        if (userPermissions.indexOf(Constants.BACKOFFICE_USER_PERMISSIONS.ALL) > -1) {
            localItems = baseItems.current
        } else {
            for (let i of baseItems.current) {
                if ("permission" in i && userPermissions.indexOf(i.permission) === -1) continue
                else if ("children" in i) {
                    i.children = i.children.filter(c => {
                        if (!("permission" in c) || userPermissions.indexOf(c.permission) > -1) return true
                        else return false
                    })
                    if (i.children.length > 0) localItems.push(i)
                } else localItems.push(i)
            }
        }
        setItems(localItems)
    }, [props.base, props.seed])

    useEffect(() => {
        const location = props.currentLocation
        const data = { menuActiveFlags: {}, subMenuActiveFlags: {} }

        for (let i of baseItems.current) {
            data.menuActiveFlags[i.text] = location.includes(i.link.split("/").splice(0, 3).join("/"))
            if ("children" in i) {
                for (let c of i.children) {
                    data.subMenuActiveFlags[c.text] = data.menuActiveFlags[i.text] && location.includes(c.link)
                }
            }
        }

        setNavData(data)
    }, [props.currentLocation])

    return (
        <div className={props.className}>
            <div className="w-full h-full flex flex-col relative">
                <IoCloseSharp className="md:hidden absolute right-4 top-4 w-7 h-7 text-white" onClick={props.closeNav} />
                <div className="py-8 flex-none relative">
                    {!isMobile && <div className="absolute w-6 h-6 p-1 top-[50%] -translate-y-1/2 translate-x-1/2 right-0 bg-white rounded-full flex items-center justify-center border border-sk-blue-select cursor-pointer" onClick={props.toggleExpanded}>
                        <MdOutlineKeyboardArrowRight className={props.expanded ? "rotate-180" : "rotate-0"} />
                    </div>}
                    <Link to={props.base} onClick={props.closeNav}><img className={"mx-auto " + (props.expanded ? "h-20" : "h-5 w-5")} src={props.expanded ? "/assets/sankash-logo.svg" : "/favicon.ico"} alt="Sankash Logo" /></Link>
                </div>
                <div className="grow flex flex-col pb-8">
                    {items.length === 0 && <p className="text-xs text-white text-center flex justify-center items-center"><CommonLoader className="h-3 w-3 text-white mr-1" />Loading...</p>}
                    {items.length > 0 && items.map((x, i) =>
                        x.children && x.children.length > 0 ?
                            <MenuItemStatic key={i} logo={x.logo || "/assets/sankash-logo.svg"} text={props.expanded ? x.text : ""} active={navData.menuActiveFlags[x.text]} expandable={props.expanded ? true : false} closeNav={props.closeNav} id={x.id} expanded={props.expanded} toggleExpanded={props.toggleExpanded}>
                                {x.children.map((y, j) =>
                                    <SubMenuItem key={j} logo={y.logo || "/assets/sankash-logo.svg"} text={props.expanded ? y.text : ""} active={navData.subMenuActiveFlags[y.text]} link={x.link + y.link} closeNav={props.closeNav} expanded={props.expanded} />
                                )}
                            </MenuItemStatic> :
                            <MenuItem key={i} logo={x.logo || "/assets/sankash-logo.svg"} text={props.expanded ? x.text : ""} active={navData.menuActiveFlags[x.text]} link={x.link} closeNav={props.closeNav} id={x.id} expanded={props.expanded} toggleExpanded={props.toggleExpanded} />
                    )}
                </div>
                <div className="flex-none py-2">
                    <p className="text-center text-white text-xs"><Link target="_blank" to="https://sankash.in">&copy; SanKash (2019 - {new Date().getFullYear()})</Link></p>
                </div>
            </div>
        </div>
    )
}

export default Nav