import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Accordion = (props) => {
    let [show, setShow] = useState(props.show || false)

    function toggle() {
        setShow(!show)
    }

    return (
        <div data-accordion="collapse" className={"border-2 border-gray border-opacity-30 rounded-xl " + props.className}>
            <h2>
                <button type="button" className="w-full p-4 font-semibold text-left" onClick={toggle}>
                    <span className="text-sm lg:text-base">{props.heading}</span>
                    <img className={"float-right w-3 h-3 mt-1.5 " + (show && "rotate-180")} src="/assets/icons/arrow-down-gray.svg" alt="expand" />
                </button>
            </h2>
            {show &&
                <div id={props.id}>
                    {props.children}
                </div>
            }
        </div>
    )
}

Accordion.propTypes = {
    show: PropTypes.bool,
    className: PropTypes.string,
    heading: PropTypes.string,
    id: PropTypes.string,
    children: PropTypes.any
}

export default Accordion