import callapi from "./callapi"
import endpoints from "../config/endpoints"
import util from './util'

const Backoffice = {
    partner: {
        check: async function (data, check) {
            return await callapi({
                method: "post",
                url: endpoints.backoffice.partner.check,
                data: {
                    data, check
                }
            })
        },
        add: async function (data, setData, setError) {
            setError(null)
            setError("Please wait. Validating data (1/3).")
            const validResponse = await this.check(data, ["data.validate"])
            if (validResponse.status === 200) {
                if (Array.isArray(validResponse.data?.error) && validResponse.data.error.length > 0) {
                    return setError("Please correct following errors: \n\n" + validResponse.data.error.join("\n"))
                }
            } else {
                return setError(validResponse.error)
            }

            const fileObjs = ["files.pan", "files.gst", "files.logo", "files.cheque"]
            if (fileObjs.some(f => !!data[f] && typeof data[f] === "object")) {
                setError(`Please wait. Uploading documents (2/3).`)
                for (let f of fileObjs) {
                    if (data[f]) {
                        const response = await util.uploadPartnerDocuments({
                            file: data[f][0],
                            ext: data[f][0].name.split(".").pop(),
                            size: data[f][0].size,
                            type: data[f][0].type
                        })
                        if (response.status === 200 && response.data?.data) {
                            setData(f, response.data.data)
                            data[f] = response.data.data
                        } else if (response.status === 413) {
                            setError("Max file size can be 5MB. Please check uploaded files.")
                            return false
                        } else {
                            setError(response.error)
                            return false
                        }
                    }
                }
            }

            setError("Please wait. Creating new partner (3/3).")
            const response = await callapi({
                method: "post",
                url: endpoints.backoffice.partner.add,
                data
            })
            if (response.status === 200 && response.data?.data) {
                setError("Partner created: " + response.data?.data)
                return true
            } else {
                setError(response.error)
                return false
            }
        },
        count: async function ({ search }) {
            return callapi({
                method: "get",
                url: endpoints.backoffice.partner.count,
                params: { search }
            })
        },
        list: async function ({ search }, pageno, download = false) {
            return callapi({
                method: "get",
                url: endpoints.backoffice.partner.list,
                params: { pageno, search, download }
            })
        },
        details: async function (id) {
            return callapi({
                method: "get",
                url: endpoints.backoffice.partner.detail(id)
            })
        },
        update: async function (id, data) {
            return callapi({
                method: "post",
                url: endpoints.backoffice.partner.detail(id),
                data
            })
        },
        search: async function (search) {
            return callapi({
                method: "get",
                url: endpoints.backoffice.partner.search,
                params: {
                    search
                }
            })
        },
        bulkUpdate: async function ({ fileName, verify }) {
            return callapi({
                method: "post",
                url: endpoints.backoffice.partner.bulkUpdate,
                data: { fileName, verify }
            })
        },
        listRMs: async function () {
            return callapi({
                method: "get",
                url: endpoints.backoffice.partner.listRMs
            })
        },
        qrCode: async function (data) {
            return callapi({
                method: "post",
                url: endpoints.backoffice.partner.qrCode,
                data
            })
        }
    },
    supervisor: {
        count: async function () {
            return callapi({
                method: "get",
                url: endpoints.backoffice.supervisor.count
            })
        },
        list: async function () {
            return callapi({
                method: "get",
                url: endpoints.backoffice.supervisor.list
            })
        }
    },
    agent: {
        count: async function ({ search }) {
            return callapi({
                method: "get",
                url: endpoints.backoffice.agent.count,
                params: { search }
            })
        },
        list: async function ({ search }, pageno) {
            return callapi({
                method: "get",
                url: endpoints.backoffice.agent.list,
                params: { search, pageno }
            })
        },
        details: async function (id) {
            return callapi({
                method: "get",
                url: endpoints.backoffice.agent.detail(id)
            })
        },
        add: async function (data) {
            const { name, email, mobile, partner, password, confirmpassword } = data

            if (!name) return { error: "Name is required." }
            if (!email) return { error: "Email is required." }
            if (!mobile) return { error: "Mobile is required." }
            if (!partner) return { error: "Partner selection is required." }
            if (!password) return { error: "Password is required." }
            if (!confirmpassword) return { error: "Confirm Password is required." }
            if (password !== confirmpassword) return { error: "Passwords do not match." }

            return callapi({
                method: "post",
                url: endpoints.backoffice.agent.add,
                data
            })
        },
        update: async function (id, data) {
            return callapi({
                method: "post",
                url: endpoints.backoffice.agent.detail(id),
                data
            })
        },
    },
    walletRequests: {
        count: async function () {
            return callapi({
                method: "get",
                url: endpoints.backoffice.walletRequests.count
            })
        },
        list: async function (pageno) {
            return callapi({
                method: "get",
                url: endpoints.backoffice.walletRequests.list,
                params: { pageno }
            })
        },
        approve: async function (id) {
            return callapi({
                method: "post",
                url: endpoints.backoffice.walletRequests.approve(id)
            })
        },
        reject: async function (id) {
            return callapi({
                method: "post",
                url: endpoints.backoffice.walletRequests.reject(id)
            })
        }
    },
    walletTransactions: {
        count: async function () {
            return callapi({
                method: "get",
                url: endpoints.backoffice.walletTransactions.count
            })
        },
        list: async function (pageno) {
            return callapi({
                method: "get",
                url: endpoints.backoffice.walletTransactions.list,
                params: { pageno }
            })
        },
        add: async function (data) {
            return callapi({
                method: "post",
                url: endpoints.backoffice.walletTransactions.add,
                data
            })
        }
    },
    applicationTransactions: {
        count: async function ({ search, status }) {
            return await callapi({
                method: "get",
                url: endpoints.backoffice.applicationTransactions.count,
                params: { search, status }
            })
        },
        list: async function ({ search, status }, pageno, download) {
            if (!pageno) pageno = 1
            return await callapi({
                method: "get",
                url: endpoints.backoffice.applicationTransactions.list,
                params: { pageno, search, status, download }
            })
        },
        detail: async function ({ id }) {
            return await callapi({
                method: "get",
                url: endpoints.backoffice.applicationTransactions.detail,
                params: { id }
            })
        },
        update: async function (id, data) {
            return await callapi({
                method: "post",
                url: endpoints.backoffice.applicationTransactions.update(id),
                data
            })
        },
        get: async function (id) {
            return await callapi({
                method: "get",
                url: endpoints.backoffice.applicationTransactions.get(id)
            })
        },
        markDisbursed: async function (id, data) {
            return await callapi({
                method: "post",
                url: endpoints.backoffice.applicationTransactions.markDisbursed(id),
                data
            })
        },
        getDisbursements: async function (id) {
            return callapi({
                method: "get",
                url: endpoints.backoffice.applicationTransactions.disbursements(id),
            })
        },
        setDisbursementTracker: async function (id, data) {
            return callapi({
                method: "post",
                url: endpoints.backoffice.applicationTransactions.disbursementTracker(id),
                data
            })
        }
    },
    insuranceTransactions: {
        count: async function ({ search, status }) {
            return await callapi({
                method: "get",
                url: endpoints.backoffice.insuranceTransactions.count,
                params: { search, status }
            })
        },
        list: async function ({ search, status }, pageno, download) {
            if (!pageno) pageno = 1
            return await callapi({
                method: "get",
                url: endpoints.backoffice.insuranceTransactions.list,
                params: { pageno, search, status, download }
            })
        },
        detail: async function ({ id }) {
            return await callapi({
                method: "get",
                url: endpoints.backoffice.insuranceTransactions.detail,
                params: { id }
            })
        },
        update: async function (id, data) {
            return await callapi({
                method: "post",
                url: endpoints.backoffice.insuranceTransactions.update(id),
                data
            })
        }
    },
    groupInsuranceTransactions: {
        count: async function () {
            return await callapi({
                method: "get",
                url: endpoints.backoffice.groupInsuranceTransactions.count,
            })
        },
        list: async function (pageno) {
            if (!pageno) pageno = 1
            return await callapi({
                method: "get",
                url: endpoints.backoffice.groupInsuranceTransactions.list,
                params: { pageno }
            })
        }
    },
    paymentCollectionTransactions: {
        count: async function () {
            return await callapi({
                method: "get",
                url: endpoints.backoffice.paymentCollectionTransactions.count,
            })
        },
        list: async function (pageno) {
            if (!pageno) pageno = 1
            return await callapi({
                method: "get",
                url: endpoints.backoffice.paymentCollectionTransactions.list,
                params: { pageno }
            })
        },
        detail: async function (id) {
            return await callapi({
                method: "get",
                url: endpoints.backoffice.paymentCollectionTransactions.detail,
                params: id
            })
        }
    },
    summary: async function (type) {
        return callapi({
            method: "get",
            url: endpoints.backoffice.summary(type)
        })
    },
    manage: {
        users: {
            count: async function ({ search }) {
                return callapi({
                    method: "get",
                    url: endpoints.backoffice.manage.count,
                    params: { search }
                })
            },
            list: async function ({ search }, pageno) {
                return callapi({
                    method: "get",
                    url: endpoints.backoffice.manage.list,
                    params: { pageno, search }
                })
            },
            update: async function (id, data) {
                return callapi({
                    method: "post",
                    url: endpoints.backoffice.manage.update(id),
                    data
                })
            },
            add: async function (data) {
                const { name, email, mobile, password, confirmpassword } = data

                if (!name) return { error: "Name is required." }
                if (!email) return { error: "Email is required." }
                if (!mobile) return { error: "Mobile is required." }
                if (!password) return { error: "Password is required." }
                if (!confirmpassword) return { error: "Confirm Password is required." }
                if (password !== confirmpassword) return { error: "Passwords do not match." }

                return callapi({
                    method: "post",
                    url: endpoints.backoffice.manage.add,
                    data
                })
            }
        }
    },
    partnerLeads: {
        count: async function () {
            return callapi({
                method: "get",
                url: endpoints.backoffice.partnerLeads.count,
            })
        },
        list: async function (pageno) {
            if (!pageno) pageno = 1
            return callapi({
                method: "get",
                url: endpoints.backoffice.partnerLeads.list,
                params: { pageno }
            })
        },
        details: async function (id) {
            return callapi({
                method: "get",
                url: endpoints.backoffice.partnerLeads.details,
                params: { id }
            })
        },
        markused: async function (id) {
            return callapi({
                method: "get",
                url: endpoints.backoffice.partnerLeads.markused,
                params: { id }
            })
        }
    }
}

export default Backoffice