import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
// eslint-disable-next-line
import { CommonForgotPassword, CommonLogout, AgentError, NotFound, WorkingCapital, AgentFlights } from "./components/index"
// eslint-disable-next-line
import { AgentLogin, AgentLayout, AgentApplicationEligibility, AgentInsuranceNew, AgentInsuranceCovers, AgentInsuranceGroup, AgentPaymentCollect, AgentTransactions, AgentApplicationEMICalc, AgentMarketingToolkit, AgentMarketingVisa, AgentUser, AgentUserChangePassword, AgentWalletHome, AgentWalletAdd, AgentSupport, AgentOnboardingExisting, AgentOnboardingSignup } from "./components/index"
// eslint-disable-next-line
import { BackofficeLogin, BackofficePartnersManage, BackofficeNewOnboardings, BackofficePartnersAdd, BackofficePartnerUpdate, BackofficePartnerQRMap, BackofficeAgentsAdd, BackofficeAgentsManage, BackofficeSupervisorManage, BackofficeSupervisorAdd, BackofficeDashboardTNPLApplications, BackofficeDashboardTNPLDisbursements, BackofficeDashboardTIPolicies, BackofficeDashboardPaymentsValue, BackofficeDashboardTNPLKeyAccounts, BackofficeDashboardTIKeyAccounts, BackofficeWalletRequests, BackofficeWalletTransactions, BackofficeWalletManualEntry, BackofficeTNPLTransactions, BackofficeTNPLReports, BackofficeTITransactions, BackofficeTIGroupInsurance, BackofficePaymentcollectionTransactions, BackofficeAdminManageUsers, BackofficeAdminAddUser } from "./components/index"
import { CustomerLayout, CustomerApplication } from './components/index'
import './App.css'
import InternalServerError from "./components/InternalServerError"
import { Tracking } from "./components/index"

import mixpanel from "./config/mixpanel"
import GA from "./config/analytics"

function App() {
    if (process.env.REACT_APP_MIXPANEL_TOKEN) mixpanel.init()
    GA.init()

    return (
        <BrowserRouter>
            <Tracking>
                <Routes>
                    {/* these routes are outside login */}
                    <Route path="/agent/auth">
                        <Route index element={<Navigate to="login" />} />
                        <Route path="login" element={<AgentLogin />} />
                        <Route path="logout" element={<CommonLogout base="/agent" />} />
                        <Route path="forgot-password" element={<CommonForgotPassword base="/agent" />} />
                    </Route>
                    <Route path="/agent/onboarding">
                        <Route index element={<Navigate to="signup" />} />
                        <Route path="existing" element={<AgentOnboardingExisting base="/agent" />} />
                        <Route path="signup" element={<AgentOnboardingSignup />} />
                    </Route>
                    <Route path="/backoffice/auth">
                        <Route index element={<Navigate to="login" />} />
                        <Route path="login" element={<BackofficeLogin />} />
                        <Route path="logout" element={<CommonLogout base="/backoffice" />} />
                        <Route path="forgot-password" element={<CommonForgotPassword base="/backoffice" email={true} />} />
                    </Route>
                    {/* these routes are behind login */}
                    <Route path="/" element={<Navigate to="agent" />} />
                    <Route path="/agent" element={<AgentLayout base="/agent" ribbon={true} />}>
                        <Route index element={<Navigate to="application/check-eligibility" />} />
                        <Route path="flights" element={<Navigate to="search" />} />
                        <Route path="flights/search" element={<AgentFlights />} />
                        <Route path="application" element={<Navigate to="check-eligibility" />} />
                        <Route path="application/check-eligibility" element={<AgentApplicationEligibility />} />
                        <Route path="application/calculate-emi" element={<AgentApplicationEMICalc />} />
                        <Route path="insurance" element={<Navigate to="new-insurance" />} />
                        <Route path="insurance/new-insurance" element={<AgentInsuranceNew />} />
                        <Route path="insurance/covers" element={<AgentInsuranceCovers />} />
                        <Route path="insurance/group-insurance-enquiry" element={<AgentInsuranceGroup />} />
                        <Route path="collect-payment" element={<AgentPaymentCollect />} />
                        <Route path="transactions" element={<AgentTransactions />} />
                        <Route path="calculate-emi" element={<AgentApplicationEMICalc />} />
                        <Route path="wallet" element={<AgentWalletHome />} />
                        <Route path="wallet/add" element={<AgentWalletAdd />} />
                        <Route path="support" element={<AgentSupport />} />
                        <Route path="marketing-toolkit" element={<AgentMarketingToolkit />} />
                        <Route path="visa-photo" element={<AgentMarketingVisa />} />
                        <Route path="error" element={<AgentError />} />
                        <Route path="internal-server-error" element={<InternalServerError />} />
                    </Route>
                    <Route path="/agent/user" element={<AgentUser base="/agent" />}>
                        <Route path="change-password" element={<AgentUserChangePassword base="/agent" />} />
                        <Route path="logout" element={<Navigate to="/agent/auth/logout" />} />
                    </Route>
                    <Route path="/backoffice" element={<AgentLayout base="/backoffice" ribbon={false} />}>
                        <Route index element={<Navigate to="tnpl/transactions" />} />
                        <Route path="dashboards" element={<Navigate to="tnpl-applications" />} />
                        <Route path="dashboards/tnpl-applications" element={<BackofficeDashboardTNPLApplications />} />
                        <Route path="dashboards/tnpl-disbursements" element={<BackofficeDashboardTNPLDisbursements />} />
                        <Route path="dashboards/ti-policies" element={<BackofficeDashboardTIPolicies />} />
                        <Route path="dashboards/payments-value" element={<BackofficeDashboardPaymentsValue />} />
                        <Route path="dashboards/tnpl-key-accounts" element={<BackofficeDashboardTNPLKeyAccounts />} />
                        <Route path="dashboards/ti-key-accounts" element={<BackofficeDashboardTIKeyAccounts />} />
                        <Route path="users" element={<Navigate to="partners/manage" />} />
                        <Route path="users/partners" element={<Navigate to="manage" />} />
                        <Route path="users/partners/manage" element={<BackofficePartnersManage />} />
                        <Route path="users/partners/add" element={<BackofficePartnersAdd />} />
                        <Route path="users/partners/update" element={<BackofficePartnerUpdate />} />
                        <Route path="users/partners/qrcode" element={<BackofficePartnerQRMap />} />
                        <Route path="users/supervisors" element={<Navigate to="manage" />} />
                        <Route path="users/supervisors/manage" element={<BackofficeSupervisorManage />} />
                        <Route path="users/supervisors/add" element={<BackofficeSupervisorAdd />} />
                        <Route path="users/agents" element={<Navigate to="manage" />} />
                        <Route path="users/agents/manage" element={<BackofficeAgentsManage />} />
                        <Route path="users/agents/add" element={<BackofficeAgentsAdd />} />
                        <Route path="users/partners/new" element={<BackofficeNewOnboardings />} />
                        <Route path="tnpl" element={<Navigate to="transactions" />} />
                        <Route path="tnpl/transactions" element={<BackofficeTNPLTransactions />} />
                        <Route path="tnpl/reports" element={<BackofficeTNPLReports type="tnpl" />} />
                        <Route path="ti" element={<Navigate to="transactions" />} />
                        <Route path="ti/transactions" element={<BackofficeTITransactions />} />
                        <Route path="ti/group-insurance" element={<BackofficeTIGroupInsurance />} />
                        <Route path="wallet/requests" element={<BackofficeWalletRequests />} />
                        <Route path="wallet/transactions" element={<BackofficeWalletTransactions />} />
                        <Route path="wallet/transactions/add" element={<BackofficeWalletManualEntry />} />
                        <Route path="more" element={<Navigate to="payment-collection/transactions" />} />
                        <Route path="more/payment-collection/transactions" element={<BackofficePaymentcollectionTransactions />} />
                        <Route path="more/admin" element={<Navigate to="users" />} />
                        <Route path="more/admin/users" element={<Navigate to="manage" />} />
                        <Route path="more/admin/users/manage" element={<BackofficeAdminManageUsers />} />
                        <Route path="more/admin/users/add" element={<BackofficeAdminAddUser />} />
                    </Route>
                    <Route path="/backoffice/user" element={<AgentUser base="/backoffice" />}>
                        <Route path="change-password" element={<AgentUserChangePassword base="/backoffice" />} />
                    </Route>
                    {/* these routes are end customer facing and outside login */}
                    <Route path="/customer" element={<CustomerLayout />}>
                        <Route path="application" element={<CustomerApplication />} />
                    </Route>
                    {/* these routes are landing pages and outside login */}
                    <Route path="/landing/working-capital" element={<WorkingCapital />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Tracking>
        </BrowserRouter>
    );
}

export default App