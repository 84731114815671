import React, { useState, useRef, useEffect } from 'react'
import { CommonFormTextInput, CommonFormButton, CommonError, CommonFormEmailInput } from '../../index'
import API from '../../../apis/index'
import { useNavigate, Link } from 'react-router-dom'
import constants from '../../../config/constants'
import MixPanel from '../../../config/mixpanel'

const Login = (props) => {
    let [error, setError] = useState(null)
    let mobileRef = useRef(null)
    let emailRef = useRef(null)
    let passwordRef = useRef(null)
    const navigate = useNavigate()
    let [loading, setLoading] = useState(false)

    useEffect(() => {
        API.auth.checkAsync((loggedIn) => {
            if (loggedIn) {
                navigate(props.base)
            }
        })
    }, [navigate, props.base])

    async function checkLogin(e) {
        e.preventDefault()
        setError(null)
        setLoading(true)
        const mobile = mobileRef?.current?.value
        const email = emailRef?.current?.value
        const password = passwordRef?.current?.value
        const base = props.base

        const response = await API.auth.login({ mobile, email, password, base }, props.email)

        if (response.status === 200 && response.data?.data?.user_type) {
            MixPanel.track(MixPanel.types.auth.login, { type: response.data?.data?.user_type })
            const user_type = response.data?.data?.user_type
            window.sessionStorage.setItem(constants.SessionVariables.UserType, user_type)
            window.sessionStorage.setItem(constants.SessionVariables.UserInitials, response.data?.data?.initials)
            window.sessionStorage.setItem(constants.SessionVariables.FirstName, response.data?.data?.first_name || "User")
            window.sessionStorage.setItem(constants.SessionVariables.Permissions, response.data?.data?.permissions)
            window.sessionStorage.setItem(constants.SessionVariables.WalletBalance, response.data?.data?.walletBalance)
            navigate(props.base)
        } else {
            setError(response.error || "API Error!")
        }
        setLoading(false)
    }

    return (
        <div className="w-full h-full flex flex-col items-center justify-center sk-background">
            <img src="/assets/sankash-logo.svg" alt="Sankash" className="w-48 mr-5 md:self-end" />
            <form onSubmit={checkLogin} className="flex flex-col bg-white mx-4 px-8 md:px-16 py-8 md:py-12 rounded-xl shadow-2xl md:*:w-96 *:w-full">
                {props.children}
                {error && <CommonError error={error} className="text-center my-2" />}
                {!props.email && <CommonFormTextInput inputRef={mobileRef} id="sk-ag-login-mobile" type="tel" minLength="10" maxLength="10" label="Mobile Number" className="my-2" />}
                {props.email && <CommonFormEmailInput inputRef={emailRef} id="sk-ag-login-email" label="Email" className="my-2" />}
                <CommonFormTextInput inputRef={passwordRef} id="sk-sg-login-password" type="password" label="Password" minLength="6" className="my-2" />
                <Link to={`${props.base}/auth/forgot-password`} className="text-sm font-medium mt-2 mb-4 underline underline-offset-4 text-gray-700">Forgot Password?</Link>
                <CommonFormButton type="submit" text="Login" className="mt-4 my-2" loading={loading} />
            </form>
        </div>
    )
}

export default Login