import React, { useState, useEffect, useCallback } from 'react'
import { CommonError, CommonFormButton, CommonLinkButton, CommonLoader, CommonTableKeyValue } from '../../../'
import API from '../../../../apis'
import MixPanel from '../../../../config/mixpanel'

const Details = (props) => {
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)

    const fetchDetails = useCallback(async function () {
        const response = await API.backoffice.agent.details(props.id)
        if (response.status === 200 && response.data.data) {
            setData(response.data.data)
            MixPanel.track(MixPanel.types.backoffice.agent.detail, { id: props.id })
        } else {
            setError(response.error || "API Error!")
        }
    }, [props.id])

    useEffect(() => {
        setData(null)
        fetchDetails()
    }, [fetchDetails, props.id])

    const update = async function (key, value) {
        setError(null)
        const response = await API.backoffice.agent.update(props.id, { [key]: value })
        if (response.status === 200) {
            MixPanel.track(MixPanel.types.backoffice.agent.update, { type: key })
            setTimeout(function () {
                setData(null)
                fetchDetails()
            }, 1200)
            return true
        } else {
            return false
        }
    }

    return (
        <div className="z-20 fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto shadow-2xl bg-gray-900 bg-opacity-50 flex flex-row-reverse" onClick={() => typeof props.onClose === "function" && props.onClose()}>
            <div className={"w-1/3 bg-white flex flex-col slide-in-right " + ((error || !data) && "justify-center items-center")} onClick={(e) => e.stopPropagation()}>
                {data === null && error === null && <p className="text-center"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Loading...</p>}
                {!data && error && <p className="text-center"><CommonError error={error} /></p>}
                {data &&
                    <div className="flex flex-col p-4 overflow-y-scroll">
                        <img src="/assets/icons/cross.svg" alt="close" className="h-7 pr-4 pb-4 inline-block self-start opacity-50 cursor-pointer" onClick={() => typeof props.onClose === "function" && props.onClose()} />
                        <div className="mt-4 flex flex-wrap *:flex-none *:mr-4 *:mb-4">
                            <CommonLinkButton text="View Applications" isOutline={true} short={true} className="text-sm px-2" to={"/backoffice/tnpl/transactions?agent=" + props.id} />
                            <CommonLinkButton text="View Insurance" isOutline={true} short={true} className="text-sm px-2" to={"/backoffice/ti/transactions?agent=" + props.id} />
                            {data.active === 1 ? <CommonFormButton text="Mark Inactive" isOutline={true} short={true} className="text-sm px-2" onClick={update.bind(this, "active", false)} showResult={true} /> : <CommonFormButton text="Mark Active" isOutline={true} short={true} className="text-sm px-2" onClick={update.bind(this, "active", true)} showResult={true} />}
                        </div>
                        {Object.keys(data).map((field, i) =>
                            Array.isArray(data[field]) && <CommonTableKeyValue key={i} className="mt-8" heading={field}>
                                {data[field].map((row, j) =>
                                    <span key={row.key} rowclassname={row.class || ""} download={row.download || null} copy={row.copy ? row.value : false}>{row.value}</span>
                                )}
                            </CommonTableKeyValue>
                        )}
                    </div>
                }
            </div>
        </div>
    )
}

export default Details