import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const SelectMobile = (props) => {
    let [selectedValue, setSelectedValue] = useState([null, null])

    function selectValue(e) {
        const val = e.target.value
        setSelectedValue([val, e.target.options[e.target.options.selectedIndex].text])
        typeof props.onChange === "function" && props.onChange(val)
    }

    useEffect(() => {
        if (props.defaultValue && props.id && selectedValue[0] === null) {
            console.log("setting default value for mobile radio: " + props.defaultValue[0])
            setTimeout(() => {
                document.getElementById(props.id).value = props.defaultValue[0]
            }, 500)
        }
    }, [props.defaultValue, props.id, selectedValue])

    return (
        <div className={"inline-block relative border rounded-xl border-sk-blue py-3 px-6 focus:outline-none " + (props.className ? props.className : "") + " " + (props.disabled ? "bg-gray-100 bg-opacity-50" : "bg-white")} tabIndex="0">
            <label htmlFor={props.id} className="text-sm block text-sk-blue font-semibold">{props.label}</label>
            <select id={props.id} className="font-medium w-full select-none focus:outline-none" onChange={selectValue} disabled={props.disabled || false}>
                <option className="text-gray-500" hidden>Select</option>
                {props.children}
            </select>
        </div>
    )
}

SelectMobile.propTypes = {
    defaultValue: PropTypes.array,
    id: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.any
}

export default SelectMobile