import React, { useState } from 'react'
import { CommonFormButton, CommonFormCurrencyInput, CommonFormTextInput, UseScript } from '../../..'
import API from '../../../../apis'
import Constants from '../../../../config/constants'
import MixPanel from '../../../../config/mixpanel'

const PaymentGateway = (props) => {
    UseScript("https://checkout.razorpay.com/v1/checkout.js")
    const [data, setData] = useState({ type: "pg" })
    const updateData = (k, v) => setData(prev => ({ ...prev, [k]: v }))

    async function onSubmit() {
        if (!data.amount) return props.onError("Please enter amount.")
        const response = await API.wallet.newPayment(data)
        if (response.status === 200 && response.data.data) {
            openRazorpay(response.data.data.key, response.data.data.order_id, response.data.data.mobile)
        } else {
            props.onError(response.error || "API Error!")
        }
    }

    async function openRazorpay(key, orderId, mobile) {
        var options = {
            "key": key,
            "amount": data.amount * 100,
            "currency": "INR",
            "name": "SanKash Pvt Ltd",
            "description": "Add to SanKash Wallet",
            "image": Constants.SANKASH_LOGO_URL,
            "order_id": orderId,
            "prefill": {
                "contact": mobile
            },
            "handler": confirmPayment,
            "theme": {
                "color": "#01BAFA"
            }
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', paymentFailure);
        rzp1.open()
        MixPanel.track(MixPanel.types.agent.wallet.rzp_open)
    }

    async function paymentFailure(response) {
        props.onError(response.error.description)
        MixPanel.track(MixPanel.types.agent.wallet.rzp_error)
    }

    async function confirmPayment({ razorpay_payment_id, razorpay_order_id, razorpay_signature }) {
        props.onError(null)
        const response = await API.wallet.confirmPayment({ payment_id: razorpay_payment_id, order_id: razorpay_order_id, signature: razorpay_signature })
        if (response.status === 200) {
            props.onSubmit(true)
        } else {
            props.onError(response.error || "API Error!")
        }
    }

    return (
        <div className="grid grid-cols-2 xl:w-2/3 xs:w-full gap-y-6 gap-x-4">
            <p className="col-span-full font-semibold">Pay with Payment Gateway</p>
            <p className="text-xs col-span-2">Instantly top-up your wallet balance using Payment Gateway. Use UPI, Credit Cards, Debit Cards, Net Banking and more.</p>
            <CommonFormCurrencyInput label="Amount" id="sk-wallet-pg-amount" onChange={(v) => updateData("amount", v)} className="col-span-2 lg:col-span-1" />
            <CommonFormTextInput label="Remarks" id="sk-wallet-pg-remarks" onChange={(v) => updateData("remarks", v)} className="col-span-2 lg:col-span-1" />
            <CommonFormButton text="Cancel" className="px-4" isOutline={true} onClick={props.onCancel} />
            <CommonFormButton text="Submit" className="px-4" onClick={onSubmit} />
        </div>
    )
}

export default PaymentGateway