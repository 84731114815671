import React, { useState } from 'react'
import { CommonLoader, CommonError, CommonPagination, CommonTable, CommonSearch } from '../..'
import API from '../../../apis'
import { default as Details } from '../../agent/transactions/pages/insurance-details'
import Constants from '../../../config/constants'
import { useSearchParams } from 'react-router-dom'
import MixPanel from '../../../config/mixpanel'

const Transactions = () => {
    let [searchParams] = useSearchParams()
    function getDefaultFilter() {
        const partner = searchParams.get("partner")
        const agent = searchParams.get("agent")
        if (partner || agent) {
            return { search: partner || agent, status: Constants.BACKOFFICE.INSURANCE_STATUS.ALL }
        }

        return { search: null, status: Constants.BACKOFFICE.INSURANCE_STATUS.ALL }
    }

    let [error, setError] = useState(null)
    let [data, setData] = useState(null)
    let [detail, setDetail] = useState(null)
    let [numPages, setNumPages] = useState(null)
    let [currentPage, setCurrentPage] = useState(null)

    async function actionsTrigger(type) {
        if (type === "CANCELLATION_COMPLETED") {
            const response = await API.backoffice.insuranceTransactions.update(detail, { [type]: true })
            if (response.status === 200) {
                MixPanel.track(MixPanel.types.backoffice.ti.action, { type })
                return true
            }
        } else if (type === "CANCELLATION_REJECTED") {
            const response = await API.backoffice.insuranceTransactions.update(detail, { [type]: true })
            if (response.status === 200) {
                MixPanel.track(MixPanel.types.backoffice.ti.action, { type })
                return true
            }
        } else if (type === "RETRY_POLICY_GENERATION" || type === "MARK_FAILED") {
            const response = await API.backoffice.insuranceTransactions.update(detail, { [type]: true })
            if (response.status === 200) {
                MixPanel.track(MixPanel.types.backoffice.ti.action, { type })
                return true
            }
        }
        return false
    }

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">TI Transactions</h6>
            <hr className="mt-3 text-gray" />
            <CommonSearch className="mt-4" context="bo_insurance" statuses={Object.values(Constants.BACKOFFICE.INSURANCE_STATUS)} defaultFilter={getDefaultFilter()} setError={setError} setData={setData} getCount={API.backoffice.insuranceTransactions.count} getList={API.backoffice.insuranceTransactions.list} setNumPages={setNumPages} setCurrentPage={setCurrentPage} currentPage={currentPage} showExport={true} />
            <p className="mt-4 border-b border-gray-300"></p>
            {error && <CommonError error={error} className="my-4" />}
            {error === null && data === null && <p className="text-center mt-4"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Please wait...</p>}
            {numPages && numPages > 0 && data && <CommonTable className="mt-4" data={data} showDetails={setDetail} />}
            {data && numPages > 1 &&
                <div className="my-6 flex justify-end">
                    <CommonPagination currentPage={currentPage} maxPages={numPages} onPageChanged={setCurrentPage} />
                </div>
            }
            {detail && <Details id={detail} onClose={() => setDetail(null)} detailAPI={API.backoffice.insuranceTransactions.detail} showActions={true} actionsTrigger={actionsTrigger} />}
        </div>
    )
}

export default Transactions