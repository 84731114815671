import React, { useState, useEffect, useRef, useCallback } from 'react'
import StaticAPI from '../../../apis/static'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import { CommonPopup } from '../..'

const AirportInput = (props) => {
    let [isShown, setIsShown] = useState(false)
    let [listAirports, setListAirports] = useState([])
    let [listDefault, setListDefault] = useState([])
    let searchRef = useRef(null)
    let [helperText, setHelperText] = useState("Loading...")
    let [selectedValue, setSelectedValue] = useState(null)
    const [type] = useState(props.getDestinationType()?.charAt(0) || "D")

    function showDropdown() {
        if (isShown === false) {
            setIsShown(true)
            setListAirports(listDefault)
        }
    }

    function hideDropdown() {
        if (isShown === true) setIsShown(false)
        setListAirports([])
        if (searchRef?.current) searchRef.current.value = ""
    }

    // function to get list of airports
    const searchAirports = useCallback(async (search = "", setDefault = false, checkSearchTerm = false) => {
        const apiResponse = await StaticAPI.searchAirports({ type, search })
        if (apiResponse.status === 200) {
            if (apiResponse.data?.data && Array.isArray(apiResponse.data.data)) {
                if (apiResponse.data.data.length > 0) {
                    if (setDefault) setListDefault(apiResponse.data.data)
                    else {
                        if (checkSearchTerm && searchRef?.current?.value !== search) return
                        setListAirports(apiResponse.data.data)
                    }
                    return apiResponse.data.data
                } else setHelperText("No results.")
            }
        } else setHelperText("API Error.")
        return null
    }, [type])

    // load default airports upon load
    useEffect(() => {
        searchAirports("", true)
    }, [searchAirports])

    useEffect(() => {
        if (props.defaultValue) {
            if (!selectedValue || props.defaultValue !== selectedValue?.code) {
                setSelectedValue({ code: props.defaultValue, location: "...", name: "..." })
                const func = async () => {
                    const list = await searchAirports(props.defaultValue, false)
                    if (list && Array.isArray(list) && list.length >= 1) {
                        setSelectedValue(list[0])
                    }
                }
                func()
            }
        } else {
            setSelectedValue(null)
        }
    }, [props.defaultValue, searchAirports, selectedValue])

    // focus on input when dropdown is shown
    useEffect(() => {
        if (isShown === true) {
            document.getElementById(props.id + "_selection").focus()
        }
    }, [isShown, props.id])

    async function loadAirports() {
        const search = searchRef?.current?.value
        setListAirports([])
        if (!search || (typeof search === "string" && search.length === 0)) {
            setListAirports(listDefault)
        } else if (search && typeof search === "string" && search.length >= 3) {
            setHelperText("Loading...")
            await searchAirports(search, false, true)
        } else {
            setHelperText("No results.")
        }
    }

    function selectValue(e, val) {
        if (e && e?.preventDefault) e.preventDefault()
        setSelectedValue(val)
        typeof props.onChange === "function" && props.onChange(val.code)
        hideDropdown()
    }

    return (
        <div className={"inline-block relative border rounded-xl border-sk-blue py-3 px-6 bg-white focus:outline-none " + (props.className ? props.className : "")} tabIndex="0" onClick={showDropdown}>
            <label htmlFor={props.id} className="text-sm block text-sk-blue font-semibold text-left">{props.label}</label>
            <p id={props.id} className={"font-medium w-full bg-white flex flex-col relative text-left " + (!selectedValue && "text-gray-500")}>
                <span className="line-clamp-1">{selectedValue ? selectedValue.name + " - " + selectedValue.location : (props.placeholder ? props.placeholder : "Select")}</span>
                {/* {selectedValue && <span className="text-sm text-gray-500 line-clamp-1">{selectedValue.name}</span>} */}
                <img className="w-3 h-3 inline invert absolute right-0 top-2" src="/assets/icons/arrow-down.svg" alt="arrow" />
            </p>
            {isMobile
                ? isShown && <CommonPopup sticky={true} onOk={() => setIsShown(false)} onCancel={() => setIsShown(false)}>
                    <input id={props.id + "_selection"} ref={searchRef} type="text" className="h-10 outline-none w-full text-center border-b border-b-gray-300" placeholder="Search Airports" onChange={loadAirports} />
                    {listAirports.length === 0 && <p className="my-3 text-center text-sm text-gray-500">{helperText}</p>}
                    {listAirports.length > 0 &&
                        <div className={"p-4 border border-gray-50 max-h-72 " + (listAirports.length > 3 ? "overflow-y-scroll" : "")}>
                            {listAirports.map(x =>
                                <p id={props.id + "_select_" + x.code} key={x.code} className="px-4 py-2 text-sm font-medium w-full bg-white hover:bg-gray-100 cursor-pointer border-b border-gray-500" onPointerDown={(e) => selectValue(e, x)}>
                                    <span className="line-clamp-1">{x.code + " - " + x.location}</span>
                                    <span className="line-clamp-1 text-sm text-gray-700">{x.name}</span>
                                    {x.code === selectedValue && <img src="/assets/icons/green-tick.svg" alt="step" className="w-4 h-4 inline float-right" />}
                                </p>
                            )}
                        </div>
                    }
                </CommonPopup>
                : <div className={"flex flex-col absolute z-40 shadow top-18 mt-1 left-0 w-full rounded-xl border bg-white border-gray-100 focus:outline-none " + (isShown ? "visible" : "hidden")} onBlur={hideDropdown}>
                    <input id={props.id + "_selection"} ref={searchRef} type="text" className="rounded-t-xl h-10 outline-none px-2 border-b border-b-gray-300" placeholder="Search Airports" onChange={loadAirports} />
                    {listAirports.length === 0 && <p className="my-3 text-center text-sm text-gray-500">{helperText}</p>}
                    {listAirports.length > 0 &&
                        <div className={"max-h-52 px-2 " + (listAirports.length > 3 ? "overflow-y-scroll" : "")}>
                            {listAirports.map(x =>
                                <p id={props.id + "_select_" + x.code} key={x.code} className="px-4 py-2 text-sm font-medium w-full rounded-xl bg-white hover:bg-gray-100 cursor-pointer" onPointerDown={(e) => selectValue(e, x)}>
                                    <span>{x.code + " - " + x.location}</span><br />
                                    <span className="text-sm text-gray-500">{x.name}</span>
                                    {x.code === selectedValue && <img src="/assets/icons/green-tick.svg" alt="step" className="w-4 h-4 inline float-right" />}
                                </p>
                            )}
                        </div>
                    }
                </div>}
        </div>
    )
}

AirportInput.propTypes = {
    id: PropTypes.string,
    getDestinationType: PropTypes.func,
    onChange: PropTypes.func,
    className: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string
}

export default AirportInput